import {
  LlmApiResponse,
  LlmModel,
  LlmMessage,
  LlmApiRequest,
} from "../../../api-worker/src/LlmApiTransport";
import { Optional } from "../index.d";

type ChainedRunEnvelope = {
  // Only set if you want to override the `PromptBuilder` default
  // model for this specific run only.
  overrideModel?: LlmModel;

  messages: Array<LlmMessage>;
};

const POUCH_SANDBOX_API_URL =
  "https://pouch-api-worker.mmmpage.workers.dev/llm/query";
export const SANDBOX_API_AUTH_KEY = "SANDBOX_API_AUTH_KEY";
const MAX_CHAINED_MESSAGES = 5;

export class PromptBuilder {
  private _model: LlmModel;

  constructor(model: LlmModel) {
    this._model = model;
  }

  async chainedRun(
    envelopes: Array<ChainedRunEnvelope>
  ): Promise<Optional<LlmApiResponse>> {
    const authorizationKey = localStorage.getItem(SANDBOX_API_AUTH_KEY);
    if (!authorizationKey) {
      throw new Error("Authorization key not found.");
    }

    if (envelopes.length > MAX_CHAINED_MESSAGES) {
      throw new Error(
        `Maximum number of chained messages exceeded. Maximum: ${MAX_CHAINED_MESSAGES}`
      );
    }

    let prevResponse: Optional<LlmApiResponse> = undefined;

    for (const envelope of envelopes) {
      const messagesWithReplacement = envelope.messages.map((message) => ({
        ...message,
        content: message.content.replace(
          "{{previous}}",
          prevResponse?.response?.content ?? ""
        ),
      }));
      // Construct request
      const request: LlmApiRequest = {
        model: envelope.overrideModel ?? this._model,
        messages: messagesWithReplacement,
      };

      const response = await fetch(POUCH_SANDBOX_API_URL, {
        method: "POST",
        headers: {
          Authorization: authorizationKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });
      prevResponse = await response.json();
    }

    return prevResponse as LlmApiResponse;
  }

  async run(messages: Array<LlmMessage>): Promise<Optional<LlmApiResponse>> {
    const authorizationKey = localStorage.getItem(SANDBOX_API_AUTH_KEY);
    if (!authorizationKey) {
      throw new Error("Authorization key not found.");
    }

    // Construct request
    const request: LlmApiRequest = {
      model: this._model,
      messages: messages,
    };

    const response = await fetch(POUCH_SANDBOX_API_URL, {
      method: "POST",
      headers: {
        Authorization: authorizationKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });
    const data = await response.json();

    return data as LlmApiResponse;
  }
}
