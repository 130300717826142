import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import React, { memo } from "react";

/**
 * A memoized wrapper around FontAwesome
 */
export const FaIcon = memo((props: FontAwesomeIconProps) => {
  /* -------------------------------------------------- */
  /*  Render                                            */
  /* -------------------------------------------------- */
  return <FontAwesomeIcon {...props} />;
});

FaIcon.displayName = "FaIcon";
