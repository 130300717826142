import { create } from "zustand";
import { LlmModel } from "../../../api-worker/src/LlmApiTransport";

type InterfaceStore = {
  isPendingMessages: boolean;
  setIsPendingMessages: (isPendingMessages: boolean) => void;

  model: LlmModel;
  setModel: (model: LlmModel) => void;
};

export const useInterfaceStore = create<InterfaceStore>((set) => ({
  isPendingMessages: false,
  setIsPendingMessages: (isPendingMessages) => set({ isPendingMessages }),

  model: "gemini-1.0",
  setModel: (model) => set({ model }),
}));
