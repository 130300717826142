import React, { memo } from "react";
import SlTooltip from "@shoelace-style/shoelace/dist/react/tooltip";
import { marked } from "marked";
import * as DOMPurify from "dompurify";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import {
  faClipboard,
  faBug,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import "./ChatLogMessage.scss";
import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";
import classNames from "classnames";
import { FaIcon } from "../FaIcon";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import { TooltipButton } from "../TooltipButton";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "../Toast";

import "../ConfirmDeleteDialog/ConfirmDeleteDialog.scss";

import { ChatMessage } from "../../store/GooeyTypes";
import { useApp } from "../../store/useApp";
type Props = {
  sender: "user" | "model";
  message: ChatMessage;
  index: number;
};

export const ChatEntry = memo(({ sender, message, index }: Props) => {
  const [open, setOpen] = React.useState(false);

  const { deleteMessage } = useApp();

  /* -------------------------------------------------- */
  /*  Render                                            */
  /* -------------------------------------------------- */

  const messageContent: string = React.useMemo(() => {
    return marked.parse(message.content, { async: false }) as string;
  }, [message, sender]);

  return (
    <>
      <SlDialog
        className="dialog"
        style={{ background: "#171717" }}
        label="Raw LLM Response"
        open={open}
        onSlAfterHide={() => setOpen(false)}
      >
        <code>{message.content}</code>
      </SlDialog>
      <div
        className={classNames("chat-entry", {
          user: sender === "user",
          model: sender === "model",
        })}
      >
        <div className="chat-header">
          <div className="icon"></div>

          {sender}

          <div className="actions">
            <CopyToClipboard
              text={message.content}
              onCopy={() => toast("Chat entry copied")}
            >
              <TooltipButton label="Copy">
                <FaIcon icon={faClipboard} />
              </TooltipButton>
            </CopyToClipboard>

            <TooltipButton label="Raw LLM Data" onClick={() => setOpen(true)}>
              <FaIcon icon={faBug} />
            </TooltipButton>

            <AlertDialog.Root>
              <AlertDialog.Trigger asChild>
                <TooltipButton label="Delete Entry">
                  <FaIcon icon={faTrash} />
                </TooltipButton>
              </AlertDialog.Trigger>

              <AlertDialog.Portal>
                <AlertDialog.Overlay className="AlertDialogOverlay" />
                <AlertDialog.Content className="AlertDialogContent">
                  <AlertDialog.Title className="AlertDialogTitle">
                    Are you absolutely sure?
                  </AlertDialog.Title>
                  <AlertDialog.Description className="AlertDialogDescription">
                    This action cannot be undone. This will permanently delete
                    this entry.
                  </AlertDialog.Description>
                  <div
                    style={{
                      display: "flex",
                      gap: 25,
                      justifyContent: "flex-end",
                    }}
                  >
                    <AlertDialog.Cancel asChild>
                      <button className="Button mauve">Cancel</button>
                    </AlertDialog.Cancel>
                    <AlertDialog.Action asChild>
                      <button
                        className="Button red"
                        onClick={() => {
                          deleteMessage(message.id);
                        }}
                      >
                        Yes, delete entry
                      </button>
                    </AlertDialog.Action>
                  </div>
                </AlertDialog.Content>
              </AlertDialog.Portal>
            </AlertDialog.Root>
          </div>
        </div>
        <div
          className="chat-body"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(messageContent),
          }}
        ></div>
        <div className="model-maker">{message.model}</div>
      </div>
    </>
  );
});

ChatEntry.displayName = "ChatEntry";
