import { create } from "zustand";
import { persist } from "zustand/middleware";
import { v4 as uuidv4 } from "uuid";
import { Map as ImmMap } from "immutable";
import {
  // Assuming your types are defined in a separate file, import them
  GooeyWorkspace,
  GooeySnippet,
  GooeyChat,
  GooeyAction,
  WorkspaceId,
  SnippetId,
  GooeyChatId,
  GooeyActionId,
  ChatMessageId,
  ChatMessage,
} from "./GooeyTypes";

interface WorkspaceState {
  workspaces: { [key: string]: GooeyWorkspace };
  snippets: { [key: string]: GooeySnippet };
  chats: { [key: string]: GooeyChat };
  actions: { [key: string]: GooeyAction };
  messages: { [key: string]: ChatMessage };

  addWorkspace: (workspace: GooeyWorkspace) => void;
  updateWorkspace: (
    workspaceId: WorkspaceId,
    fn: (workspace: GooeyWorkspace) => GooeyWorkspace
  ) => void;
  removeWorkspace: (workspaceId: WorkspaceId) => void;

  addChat: (chat: GooeyChat) => void;
  updateChat: (chatId: GooeyChatId, fn: (chat: GooeyChat) => GooeyChat) => void;
  removeChat: (chatId: GooeyChatId) => void;

  addAction: (action: GooeyAction) => void;

  addMessage: (message: ChatMessage) => void;
  removeMessage: (messageId: ChatMessageId) => void;
  updateMessage: (
    messageId: ChatMessageId,
    fn: (message: ChatMessage) => ChatMessage
  ) => void;

  addSnippet: (snippet: GooeySnippet) => void;
  removeSnippet: (snippetId: SnippetId) => void;
}

export const useWorkspaceStore = create<WorkspaceState, any>(
  persist(
    (set) => ({
      // Initial objects

      workspaces: {},
      snippets: {},
      chats: {},
      actions: {},
      messages: {},

      // Actions to modify the state using object methods
      addWorkspace: (workspace) =>
        set((state) => ({
          workspaces: { ...state.workspaces, [workspace.id]: workspace },
        })),
      addSnippet: (snippet) =>
        set((state) => ({
          snippets: { ...state.snippets, [snippet.id]: snippet },
        })),
      addChat: (chat) =>
        set((state) => ({
          chats: { ...state.chats, [chat.id]: chat },
        })),
      updateWorkspace(workspaceId, fn) {
        set((state) => ({
          workspaces: {
            ...state.workspaces,
            [workspaceId]: fn(state.workspaces[workspaceId]),
          },
        }));
      },
      updateChat: (chatId, fn) =>
        set((state) => {
          return {
            chats: {
              ...state.chats,
              [chatId]: fn(state.chats[chatId]),
            },
          };
        }),
      removeSnippet(snippetId) {
        set((state) => {
          const { [snippetId]: _, ...newSnippets } = state.snippets;
          return { snippets: newSnippets };
        });
      },
      addAction: (action) =>
        set((state) => ({
          actions: { ...state.actions, [action.id]: action },
        })),
      addMessage: (message) =>
        set((state) => ({
          messages: { ...state.messages, [message.id]: message },
        })),
      removeMessage: (messageId) =>
        set((state) => {
          const { [messageId]: _, ...newMessages } = state.messages;
          return { messages: newMessages };
        }),
      updateMessage: (messageId, fn) =>
        set((state) => ({
          messages: {
            ...state.messages,
            [messageId]: fn(state.messages[messageId]),
          },
        })),
      removeChat: (chatId) =>
        set((state) => {
          const { [chatId]: _, ...newChats } = state.chats;
          return { chats: newChats };
        }),
      removeWorkspace: (workspaceId) =>
        set((state) => {
          const { [workspaceId]: _, ...newWorkspaces } = state.workspaces;
          return { workspaces: newWorkspaces };
        }),
    }),
    {
      name: "workspace-store", // unique name of the store
      getStorage: () => localStorage, // specifies localStorage as the storage option
    }
  )
);
