// usePromptStore.ts
import create from "zustand";
import { v4 as uuidv4 } from "uuid";
import { Map as ImmMap } from "immutable";

interface PromptState {
  prompt: string;
  setPrompt: (prompt: string) => void;
  cachedRecentPrompts: string[];
  setCachedRecentPrompts: (cachedRecentPrompts: string[]) => void;

  // Key will be some uuid to uniquely identify the "PromptSupplement"
  activePromptSupplements: ImmMap<string, string>;
  addActivePromptSupplement: (
    promptLabel: string,
    activePromptSupplement: string
  ) => void;
  removeActivePromptSupplement: (activePromptSupplement: string) => void;
}

const usePromptStore = create<PromptState>((set) => ({
  prompt: "",
  setPrompt: (prompt) => set(() => ({ prompt })),
  cachedRecentPrompts: [],
  setCachedRecentPrompts: (cachedRecentPrompts) =>
    set(() => ({ cachedRecentPrompts })),

  activePromptSupplements: ImmMap(),
  addActivePromptSupplement: (promptLabel, activePromptSupplement) =>
    set((state) => ({
      activePromptSupplements: state.activePromptSupplements.set(
        promptLabel,
        activePromptSupplement
      ),
    })),
  removeActivePromptSupplement: (promptSupplement) =>
    set((state) => ({
      activePromptSupplements:
        state.activePromptSupplements.remove(promptSupplement),
    })),
}));

export default usePromptStore;
