import React, { memo } from "react";

import SlTooltip from "@shoelace-style/shoelace/dist/react/tooltip";

import "./TooltipButton.scss";
import classnames from "classnames";

type Props = {
  children?: React.ReactNode;
  label?: string;
  onClick?: () => void;
  autoWidth?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export const TooltipButton = memo(
  ({ children, label, onClick, autoWidth, className, style }: Props) => {
    /* -------------------------------------------------- */
    /*  Render                                            */
    /* -------------------------------------------------- */

    return (
      <SlTooltip className={classnames("tooltip")} content={label}>
        <button
          onClick={onClick}
          className={classnames("tooltip-button", {
            "auto-width": autoWidth,
            [className!]: className !== undefined,
          })}
          style={style}
        >
          {children}
        </button>
      </SlTooltip>
    );
  }
);

TooltipButton.displayName = "TooltipButton";
