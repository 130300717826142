import React, { memo } from "react";

import "./WorkspaceContainer.scss";
import { useApp } from "../../store/useApp";
import { TooltipButton } from "../TooltipButton";
import { FaIcon } from "../FaIcon";
import {
  faAdd,
  faClipboard,
  faFileExport,
  faKey,
  faTrash,
  faPen,
  faClose,
  faArrowUpFromArc,
  faArrowDownFromArc,
} from "@fortawesome/pro-regular-svg-icons";
import { Demo } from "./Demo";

type Props = {};

export const WorkspaceContainer = memo(({}: Props) => {
  const { deleteWorkspace, chats, getChat, createChat, setLocationId } =
    useApp();

  const { locationId, getWorkspace } = useApp();
  const workspace = getWorkspace(locationId!);

  /* -------------------------------------------------- */
  /*  Render                                            */
  /* -------------------------------------------------- */

  if (workspace === undefined) return <></>;

  return (
    <div id={"workspace-container"}>
      <nav>
        <div className="left">{workspace.name}</div>
        <div className="right">
          <TooltipButton
            onClick={() => {
              const ok = window.confirm("Are you sure?");
              if (ok) deleteWorkspace(locationId!);
            }}
          >
            <FaIcon icon={faTrash} />
          </TooltipButton>
        </div>
      </nav>

      <div className="content">
        <Demo />

        <section id="chats-and-docs">
          <h3>Summary</h3>
        </section>

        <section id="chats-and-docs">
          <h3>Chats</h3>

          <div className="content-grid">
            {workspace.chatIds.map((id) => (
              <button
                className="asset-card"
                onClick={() => {
                  setLocationId("chat", id);
                }}
              >
                <h3>{getChat(id).summary?.summary}</h3>
                <span>{getChat(id).summary?.keywords.join(", ")}</span>
              </button>
            ))}
          </div>

          <button
            onClick={() => {
              const chat = createChat("", locationId);
              setLocationId("chat", chat.id);
            }}
          >
            New Chat
          </button>
        </section>

        <section id="chats-and-docs">
          <h3>Activity Log</h3>
        </section>
      </div>
    </div>
  );
});

WorkspaceContainer.displayName = "WorkspaceContainer";
