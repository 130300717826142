import React, { memo } from "react";
import { PromptBuilder } from "../../api/PromptBuilder";

type Props = {};

export const Demo = memo(({}: Props) => {
  const [response, setResponse] = React.useState<string | null>(null);

  // I want this to call PromptBuilder, which will take in a prompt

  const handleClick = React.useCallback(() => {
    const run = async () => {
      const response = await new PromptBuilder("gemini-1.0").chainedRun([
        {
          messages: [
            {
              role: "user",
              content: "Can you list the best restaurants in Schaumburg?",
            },
          ],
        },
        {
          messages: [
            {
              role: "user",
              content:
                "Can you give me the list, but only the names: {{previous}}",
            },
          ],
        },
      ]);

      setResponse(response?.response?.content ?? "No response");
    };

    run();
  }, []);

  /* -------------------------------------------------- */
  /*  Render                                            */
  /* -------------------------------------------------- */

  return (
    <div id={"new-react-component"} style={{ color: "#fff" }}>
      {response}
      <button onClick={handleClick}>Run Query</button>
    </div>
  );
});

Demo.displayName = "Demo";
