import React, { useCallback } from "react";
import { useWorkspaceStore } from "./useWorkspaceStore";
import { v4 as uuidv4 } from "uuid";
import {
  ChatMessage,
  ChatMessageHighlight,
  ChatMessageId,
  ChatSummary,
} from "./GooeyTypes";
import { LlmMessage, LlmModel } from "../../../api-worker/src/LlmApiTransport";
import { Optional } from "../index.d";
import { useLocation, useNavigate } from "react-router-dom";
import { useInterfaceStore } from "./useInterfaceStore";

export function useApp() {
  const {
    workspaces,
    snippets,
    chats,
    actions,
    messages,
    updateWorkspace,
    addMessage,
    addWorkspace,
    removeChat,
    removeSnippet,
    removeMessage,
    removeWorkspace,
    addChat,
    updateChat,
    updateMessage,
  } = useWorkspaceStore();

  const { model, setModel, isPendingMessages, setIsPendingMessages } =
    useInterfaceStore();

  /* -------------------------------------------------- */
  /*  Location                                          */
  /* -------------------------------------------------- */

  const location = useLocation();
  const navigate = useNavigate();

  const locationId = React.useMemo(() => {
    const parts = location.pathname.split("/");
    const trimmed = parts[parts.length - 1].trim();
    return trimmed === "" ? undefined : trimmed;
  }, [location]);

  const setLocationId = useCallback((space: "space" | "chat", id: string) => {
    navigate(`/${space}/${id}`);
  }, []);

  /* -------------------------------------------------- */
  /*  Workspace                                         */
  /* -------------------------------------------------- */

  const getWorkspace = useCallback(
    // @ts-ignore
    (id: string) => workspaces[id],
    [workspaces]
  );

  const createWorkspace = useCallback(
    (name: string, emoji: string) => {
      const workspace = {
        id: uuidv4(),
        name,
        snippetIds: [],
        chatIds: [],
        properties: { emoji },
        createdAtMs: Date.now(),
        updatedAtMs: Date.now(),
      };

      addWorkspace(workspace);
      return workspace;
    },
    [addWorkspace]
  );

  const deleteWorkspace = useCallback(
    (id: string) => {
      removeWorkspace(id);

      // Remove all chats
      const workspace = getWorkspace(id);
      workspace.chatIds.forEach((chatId) => {
        removeChat(chatId);
      });

      // Remove all snippets
      workspace.snippetIds.forEach((snippetId) => {
        removeSnippet(snippetId);
      });
    },
    [removeWorkspace, getWorkspace, removeChat, removeSnippet]
  );

  /* -------------------------------------------------- */
  /*  Snippets                                          */
  /* -------------------------------------------------- */

  /* -------------------------------------------------- */
  /*  Chats                                             */
  /* -------------------------------------------------- */

  const getChat = useCallback((id: string) => chats[id], [chats]);

  const createChat = useCallback(
    (name: string, workspaceId: Optional<string>) => {
      const chat = {
        id: uuidv4(),
        name,
        workspaceId,
        messageIds: [],
        summary: undefined,
        properties: {},
        lastMessageAt: Date.now(),
        createdAtMs: Date.now(),
        updatedAtMs: Date.now(),
      };

      addChat(chat);

      if (workspaceId) {
        updateWorkspace(workspaceId, (workspace) => ({
          ...workspace,
          chatIds: [...workspace.chatIds, chat.id],
          updatedAtMs: Date.now(),
        }));
      }

      return chat;
    },
    [addChat, updateWorkspace]
  );

  const updateChatSummary = useCallback(
    (chatId: string, summary: ChatSummary) => {
      updateChat(chatId, (chat) => ({
        ...chat,
        summary,
        updatedAtMs: Date.now(),
      }));
    },
    [updateChat]
  );

  const createMessage = (
    chatId: string,
    message: LlmMessage,
    model: LlmModel
  ) => {
    const chatMessage = {
      id: uuidv4(),
      chatId,
      role: message.role,
      content: message.content,
      model: model,
      highlights: [],
      createdAtMs: Date.now(),
    };

    addMessage(chatMessage);
    updateChat(chatId, (chat) => {
      return {
        ...chat,
        messageIds: [...chat.messageIds, chatMessage.id],
        lastMessageAt: Date.now(),
        updatedAtMs: Date.now(),
      };
    });
  };

  const deleteMessage = (messageId: ChatMessageId) => {
    const chatId = messages[messageId]?.chatId;
    removeMessage(messageId);
    updateChat(chatId!, (chat) => ({
      ...chat,
      messageIds: chat.messageIds.filter((id) => id !== messageId),
      updatedAtMs: Date.now(),
    }));
  };

  const createHighlight = (
    messageId: string,
    highlight: ChatMessageHighlight
  ) => {
    updateMessage(messageId, (message) => ({
      ...message,
      highlights: [...message.highlights, highlight],
      updatedAtMs: Date.now(),
    }));
  };

  return {
    isPendingMessages,
    setIsPendingMessages,
    messages,
    model,
    setModel,
    chats,
    getMessage: (id: string) => messages[id],
    workspaces,
    removeChat,
    setLocationId,
    locationId,
    deleteWorkspace,
    getChat,
    updateChatSummary,
    getWorkspace,
    createChat,
    createMessage,
    createWorkspace,
    createHighlight,
    deleteMessage,
    getChatMessages: (chatId: string) => {
      const chat = getChat(chatId);
      return chat?.messageIds.map((id) => messages[id]) ?? [];
    },
  };
}
