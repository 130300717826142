import React from "react";
import logo from "./logo.svg";
import { Theme } from "@radix-ui/themes";
import "./core/reset.scss";
import "./App.scss";

import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./Routes";
import { ToastContainer } from "./components/Toast/ToastContainer";

import { LeftSidebar } from "./components/LeftSidebar";
import { Toolbar } from "./components/Toolbar";
import { RightSidebar } from "./components/RightSidebar";

function App() {
  return (
    <div className="App">
      <Router>
        <Theme appearance="dark">
          <div id={"app-container"}>
            <ToastContainer />
            <LeftSidebar />
            <Routes />
            <Toolbar />
            <RightSidebar />
          </div>
        </Theme>
      </Router>
    </div>
  );
}

export default App;
