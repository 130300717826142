import React, { memo } from "react";

import "./Toolbar.scss";

type Props = {};

export const Toolbar = memo(({}: Props) => {
  /* -------------------------------------------------- */
  /*  Render                                            */
  /* -------------------------------------------------- */

  return (
    <div id={"toolbar"}>
      <></>
    </div>
  );
});

Toolbar.displayName = "Toolbar";
