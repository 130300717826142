import React, { memo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  faAdd,
  faClipboard,
  faFileExport,
  faKey,
  faTrash,
  faPen,
  faClose,
  faArrowUpFromArc,
  faArrowDownFromArc,
} from "@fortawesome/pro-regular-svg-icons";
import * as Form from "@radix-ui/react-form";
import * as Dialog from "@radix-ui/react-dialog";

import classNames from "classnames";
import { TooltipButton } from "../TooltipButton";
import { FaIcon } from "../FaIcon";

import { toast } from "../Toast";

import { SANDBOX_API_AUTH_KEY } from "../../api/PromptBuilder";
import { useApp } from "../../store/useApp";
import { LlmModel } from "../../../../api-worker/src/LlmApiTransport";

import "./LeftSidebar.scss";
import "./Dialog.scss";
import { useNavigate } from "react-router-dom";

type Props = {};

export const LeftSidebar = memo(({}: Props) => {
  const {
    locationId,
    model,
    chats,
    setModel,
    workspaces,
    createWorkspace,
    setLocationId,
    messages,
    removeChat,
  } = useApp();

  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isAuthKeyDialogOpen, setIsAuthKeyDialogOpen] = React.useState(false);

  /* -------------------------------------------------- */
  /*  Render                                            */
  /* -------------------------------------------------- */

  return (
    <aside id={"clone-sidebar"}>
      <div id="session-meta" className="cell">
        <select
          onChange={(event) => {
            setModel(event.target.value as LlmModel);
          }}
          value={model}
        >
          <option value={"llama-2-chat-70b"}>LLaMa 70B</option>
          <option value="gemini-1.0">Gemini 1.0</option>
          <option value="claude-opus">Claude Opus</option>
          <option value="command-r-plus">Command R+</option>
          <option value="gpt-4-turbo">GPT-4 Turbo</option>
        </select>

        {model !== "gemini-1.0" && (
          <div id="model-type-warning">
            Hey! You're using credits for these API call. Be careful.
          </div>
        )}
      </div>

      <div className="cell">
        <h3>Spaces</h3>
        <TooltipButton
          label="New Space"
          onClick={() => {
            const name = prompt("Name your space:");
            if (name) {
              const workspace = createWorkspace(name!, "🐑");
              setLocationId("space", workspace.id);

              toast("Space created");
            }
          }}
          style={{ position: "absolute", top: 12, right: 12 }}
        >
          <FaIcon icon={faAdd} />
        </TooltipButton>

        <div className="workspaces-grid">
          {Object.values(workspaces).map((workspace) => (
            <TooltipButton
              key={workspace.id}
              className="workspace-button"
              label="Open Workspace"
              onClick={() => setLocationId("space", workspace.id)}
            >
              <div className="emoji">{workspace.properties.emoji}</div>
              <div className="label">{workspace.name}</div>
            </TooltipButton>
          ))}
        </div>
      </div>

      <div className="cell session-list-container">
        <h3>Ungrouped Chats</h3>
        <ul id="session-list">
          {Object.entries(chats).map(([chatId, chat]) => (
            <li
              key={chatId}
              className={classNames({ active: chatId === locationId })}
            >
              <button
                className="main"
                onClick={() => {
                  navigate(`chat/${chatId}`);
                }}
              >
                <>{chat.name}</>
              </button>
              <button
                className="delete-button"
                onClick={() => {
                  if (locationId) removeChat(chatId);
                }}
              >
                <FaIcon icon={faTrash} />
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="cell stickied-footer">
        <Dialog.Root
          open={isAuthKeyDialogOpen}
          onOpenChange={setIsAuthKeyDialogOpen}
        >
          <Dialog.Trigger asChild>
            <TooltipButton label="Set Authorization Key">
              <FaIcon icon={faKey} />
            </TooltipButton>
          </Dialog.Trigger>

          <Dialog.Portal>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent">
              <Dialog.Title className="DialogTitle">
                Set Authorization Key
              </Dialog.Title>

              <Form.Root
                onSubmit={(event) => {
                  event.preventDefault();
                  const data = Object.fromEntries(
                    new FormData(event.currentTarget)
                  );

                  const parsedKey = data["auth-key"] as string;
                  localStorage.setItem(SANDBOX_API_AUTH_KEY, parsedKey);
                  setIsDialogOpen(false);
                }}
              >
                <Form.Field name="auth-key" className="Fieldset">
                  <Form.Label className="Label">Auth Key</Form.Label>
                  <Form.Control
                    id="name"
                    className="Input"
                    type="text"
                    required
                  />
                </Form.Field>

                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "flex-end",
                  }}
                >
                  <Form.Submit className="Button green">Set Key</Form.Submit>
                </div>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close">
                    <FaIcon icon={faClose} />
                  </button>
                </Dialog.Close>
              </Form.Root>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>

        {/* <CopyToClipboard text={} onCopy={() => toast("Session state copied")}>
          <TooltipButton label="Copy Session State">
            <FaIcon icon={faArrowUpFromArc} />
          </TooltipButton>
        </CopyToClipboard> */}

        <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <Dialog.Trigger asChild>
            <TooltipButton label="Import Session State">
              <FaIcon icon={faArrowDownFromArc} />
            </TooltipButton>
          </Dialog.Trigger>

          <Dialog.Portal>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent">
              <Dialog.Title className="DialogTitle">
                Import Session Data
              </Dialog.Title>

              <Form.Root
                onSubmit={(event) => {
                  event.preventDefault();
                  const data = Object.fromEntries(
                    new FormData(event.currentTarget)
                  );

                  const parsedSessionData = JSON.parse(
                    data["session-data"] as string
                  );
                  setIsDialogOpen(false);
                }}
              >
                <Form.Field name="session-data" className="Fieldset">
                  <Form.Label className="Label">Session Data</Form.Label>
                  <Form.Control
                    id="name"
                    className="Input"
                    type="text"
                    required
                  />
                </Form.Field>

                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "flex-end",
                  }}
                >
                  <Form.Submit className="Button green">Import</Form.Submit>
                </div>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close">
                    <FaIcon icon={faClose} />
                  </button>
                </Dialog.Close>
              </Form.Root>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
    </aside>
  );
});

LeftSidebar.displayName = "LeftSidebar";
