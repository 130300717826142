import React, { memo } from "react";

import usePromptStore from "../../store/usePromptStore";

import "./RightSidebar.scss";

type Props = {};

var SHEETS_API_KEY = "AIzaSyCfQbvzAI9zo46qhKdflgvLXEyJadNfOi8";
var HARDCODED_SHEETS_ID = "1GuLzrUTVZlhQtLWwZDjMe079K_CabGe-N0wemWIziG8";
var SHEETS_RANGE = "!D1:D";
var SHEETS_USER = "Eliot";

export const RightSidebar = memo(({}: Props) => {
  const { addActivePromptSupplement } = usePromptStore();

  const googleSheetsAPI = `https://sheets.googleapis.com/v4/spreadsheets/${HARDCODED_SHEETS_ID}/values/${SHEETS_USER}${SHEETS_RANGE}?key=${SHEETS_API_KEY}`;

  React.useEffect(() => {
    fetch(googleSheetsAPI)
      .then((response) => response.json())
      .then((data) => {
        // Process the fetched data here
        const sheetsData = data.values.map((entry: string[]) => entry[0]);
        const formattedSheetsData = sheetsData.join(" ");

        addActivePromptSupplement("fig", formattedSheetsData);

        // Truncates to the last 5000 characters
        addActivePromptSupplement("fig-short", formattedSheetsData);
      })
      .catch((error) => {
        // Handle any errors that occur during the fetch
        console.error(error);
      });
  }, []);

  /* -------------------------------------------------- */
  /*  Render                                            */
  /* -------------------------------------------------- */

  return (
    <aside id="right-sidebar">
      <div className="cell"></div>
    </aside>
  );
});

RightSidebar.displayName = "RightSidebar";
