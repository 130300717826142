import React from "react";
import { Route, Routes as Switch, useLocation } from "react-router-dom";

import { WorkspaceContainer } from "./components/WorkspaceContainer";
import { ChatContainer } from "./components/ChatContainer";
import { DefaultContainer } from "./components/DefaultContainer";

export const Routes = (): React.ReactElement => {
  return (
    <>
      <Switch>
        {/* FIXME: Replace this with ChatContainer */}
        <Route path="/" element={<DefaultContainer />} />
        <Route path="/chat/:id" element={<ChatContainer />} />
        <Route path="/space/:id" element={<WorkspaceContainer />} />
      </Switch>
    </>
  );
};
