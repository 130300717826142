import React, { memo } from "react";
import { ChatTextarea } from "./ChatTextarea";

type Props = {};

export const DefaultContainer = memo(({}: Props) => {
  /* -------------------------------------------------- */
  /*  Render                                            */
  /* -------------------------------------------------- */

  return (
    <div
      id={"default-container"}
      style={{ position: "relative", width: "100%" }}
    >
      <div
        className="default-message"
        style={{
          color: "rgba(255, 255, 255, 0.24)",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        You have no active chats...
      </div>

      <ChatTextarea />
    </div>
  );
});

DefaultContainer.displayName = "DefaultContainer";
