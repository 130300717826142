import React, { memo } from "react";

import usePromptStore from "../../store/usePromptStore";
import SlTextarea from "@shoelace-style/shoelace/dist/react/textarea";
import { PromptBuilder } from "../../api/PromptBuilder";
// import { ExperimentalButtons } from "./ExperimentalButtons";
import { useApp } from "../../store/useApp";
import { LlmMessage } from "../../../../api-worker/src/LlmApiTransport";
import { toast } from "../Toast";

import "./ChatTextarea.scss";

type Props = {};

export const ChatTextarea = memo(({}: Props) => {
  const {
    prompt,
    setPrompt,
    activePromptSupplements,
    removeActivePromptSupplement,
  } = usePromptStore();

  const {
    locationId,

    createChat,
    setLocationId,
    getChat,
    getMessage,
    updateChatSummary,
    createMessage,
    model,

    isPendingMessages,
    setIsPendingMessages,
  } = useApp();

  /* -------------------------------------------------- */
  /*  Handle Submit                                     */
  /* -------------------------------------------------- */

  // FIXME: Move this elsewhere!
  const submit = React.useCallback(async () => {
    // NOTE: Handle if there is no active chat
    let actualLocationId = locationId;
    if (actualLocationId === undefined) {
      const chat = createChat(prompt, undefined);
      setLocationId("chat", chat.id);
      actualLocationId = chat.id;

      return;
    }

    // Do a bit of prompt replacement
    let newPrompt = prompt;
    newPrompt = activePromptSupplements
      .toArray()
      .reduce((acc, [key, supplement]) => {
        return acc.replace(`{${key}}`, supplement);
      }, newPrompt);

    const message: LlmMessage = { role: "user", content: newPrompt || "" };
    setPrompt("");
    createMessage(actualLocationId, message, model);

    const chat = getChat(actualLocationId);
    const messages: LlmMessage[] = chat.messageIds.map((id) => {
      const message = getMessage(id);
      return { role: message.role, content: message.content };
    });
    messages.push(message);

    setIsPendingMessages(true);
    let response = undefined;
    try {
      response = await new PromptBuilder(model).run(messages);
      if (response?.status === "error") {
        toast(`ERROR: ${response.feedback}`);
        return;
      }
    } catch (e) {
      console.error(e);
      setIsPendingMessages(false);
      toast(`ERROR: ${e}`);
      return;
    }
    setIsPendingMessages(false);

    if (response?.response !== undefined) {
      createMessage(actualLocationId, response.response, model);
    }

    if (chat.messageIds.length < 2 || chat.messageIds.length % 4 === 0) {
      const messages = chat.messageIds.map((id) => getMessage(id).content);

      const summarizerRes = await new PromptBuilder(model).run([
        {
          role: "user",
          content: `
I am creating notecards representing a chat conversation.

I want to you to provide me:
1. Summary. What is a brief summary of the goal or form conversation? Less than 4 words.
2. Keywords. Give me phrases or words unique to this conversation. Limit to 5.

Return as:
{
  "summary": "This is a summary",
  "keywords": ["keyword 1", "keyword 2"]
  //  Make sure it is in square array brackets.
}

This is the message:
${messages}

Only return that JSON object. Provide no other commentary. Be concise.
`,
        },
      ]);

      const { summary, keywords } = JSON.parse(
        summarizerRes?.response?.content!
      );

      updateChatSummary(actualLocationId, { summary, keywords });
    }
  }, [
    locationId,
    prompt,
    activePromptSupplements,
    setPrompt,
    createMessage,
    model,
    getChat,
    setIsPendingMessages,
    createChat,
    setLocationId,
    getMessage,
    updateChatSummary,
  ]);

  const handleSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      submit();
    },
    [submit]
  );

  /* -------------------------------------------------- */
  /*  Render                                            */
  /* -------------------------------------------------- */
  //test

  let placeholder = "";
  if (isPendingMessages) {
    placeholder = "Waiting...";
  } else if (locationId === undefined) {
    placeholder = "Type to start a new chat...";
  } else {
    placeholder = "Message Gooey...";
  }

  return (
    <form
      id="form-tho"
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          handleSubmit(e);
        }
      }}
    >
      {/* <ExperimentalButtons /> */}

      <SlTextarea
        onSlInput={(event) => {
          // @ts-ignore
          setPrompt(event.target.value);
        }}
        id={"chat-input"}
        placeholder={placeholder}
        value={prompt}
        rows={2}
        resize={"auto"}
        disabled={isPendingMessages}
      ></SlTextarea>

      <div className="prompt-supplements">
        {activePromptSupplements.toArray().map(([key, supplement]) => (
          <button
            type="button"
            key={key}
            className="prompt-supplement"
            onClick={() => {
              setPrompt(`${prompt} {${key}}`);
            }}
          >
            {`{${key}}`}
          </button>
        ))}
      </div>
    </form>
  );
});

ChatTextarea.displayName = "ChatInput";
