import React, { memo } from "react";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import "./ChatContainer.scss";
import { PromptBuilder } from "../../api/PromptBuilder";
import { LlmMessage } from "../../../../api-worker/src/LlmApiTransport";
import { ChatTextarea } from "../ChatTextarea";
import { useTextSelection } from "../../hooks/useTextSelection";
import { useApp } from "../../store/useApp";
import { ChatEntry } from "../ChatLogMessage";
import { FaIcon } from "../FaIcon";
// import { SmartTooltip } from "../SmartTooltip";

type Props = {};

export const ChatContainer = memo(({}: Props) => {
  const { getChatMessages, locationId, isPendingMessages } = useApp();

  const chatLogRef = React.useRef<HTMLDivElement>(null);

  const { textContent, clientRect } = useTextSelection(chatLogRef.current!);

  /* -------------------------------------------------- */
  /*  Render                                            */
  /* -------------------------------------------------- */

  return (
    <>
      {/* <SmartTooltip
        top={clientRect ? clientRect?.top + clientRect?.height / 2 : 0}
        left={clientRect ? clientRect?.left + clientRect?.width / 2 : 0}
        selectedText={textContent ?? ""}
        visible={textContent !== undefined && textContent.length > 0}
      /> */}
      <div id={"chat-container"}>
        <div className="chat-log" ref={chatLogRef}>
          {getChatMessages(locationId!).map((message, index) => (
            <ChatEntry
              key={index}
              index={index}
              sender={message.role}
              message={message}
            />
          ))}

          {isPendingMessages && (
            <FaIcon
              className="fa-spin"
              style={{ color: "#fff", padding: "20px 0" }}
              icon={faSpinner}
            />
          )}
        </div>

        <ChatTextarea />
      </div>
    </>
  );
});

ChatContainer.displayName = "ChatContainer";
