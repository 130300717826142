import React, { memo } from "react";

import { ToastContainer as ToastifyContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./ToastContainer.scss";

type Props = {};

export const ToastContainer = memo(({}: Props) => {
  /* -------------------------------------------------- */
  /*  Render                                            */
  /* -------------------------------------------------- */

  return (
    <ToastifyContainer
      className="toast-container"
      position="top-center"
      theme="dark"
      closeOnClick={true}
      closeButton={false}
      hideProgressBar={true}
      autoClose={1000}
    />
  );
});

ToastContainer.displayName = "ToastContainer";
